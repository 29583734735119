import { message } from "antd";
import i18n from "i18n";
import { GET_STATUS_WISE_COUNT_QUERY_KEY } from "queryHook/statusCount";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import {
  AdditionalInstallationInformation,
  BarrierDetailsSchema,
  InstallationSchema,
  LicensePlates,
  MultiStatus,
  UpdatedPrice,
  UpdateInstallationRequestBodyDTO,
} from "types/installation";
import { INSTALLATION_DATA_REFETCH_INTERVAL } from "utils/global/constants";

const { t } = i18n;

const endPoint = "/installation";
export const INSTALLATION_LIST_QUERY_KEY = "installation-list";

export const useInstallationList = (params) => {
  return useQuery<PaginatedResponse<InstallationSchema>>({
    queryKey: [INSTALLATION_LIST_QUERY_KEY, params],
    queryFn: () =>
      new AuthorizedService()
        .get(endPoint, params)
        .then((res: any) => res.data),
    refetchOnWindowFocus: true,
    refetchInterval: INSTALLATION_DATA_REFETCH_INTERVAL,
  });
};

export const useInstallationUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: UpdateInstallationRequestBodyDTO) =>
      new AuthorizedService().put(`${endPoint}/${id}`, data).then((res) => res),
    onSuccess: () => {
      queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
    },
  });
};

export const useInstallationMultiStatusUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Record<MultiStatus, boolean>) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/change-multi-status`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("warnings:statusSuccess"));
      },
      onError: () => {
        message.error(t("warnings:error"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
        queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
      },
    }
  );
};

type AdditionalInformation = {
  additionalInformation: Partial<AdditionalInstallationInformation>;
  licensePlates: Partial<LicensePlates>;
  updatedPrice?: UpdatedPrice;
  barrierDetails: BarrierDetailsSchema;
  setupDate: string;
  setupTime: string;
  pickupDate: string;
  updatedDateRangeFrom: string;
  updatedDateRangeTo: string;
};

export const useInstallationAdditionalInformationUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: AdditionalInformation) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/additional-information`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
        queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
      },
    }
  );
};

type AdminInformation = {
  notice?: string | null;
};

export const useInstallationAdminInformationUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: AdminInformation) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/admin-information`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      },
    }
  );
};

type WorkerInformation = {
  workerNotice?: string | null;
  workerInstructionNumber?: number | null;
};

export const useInstallationWorkerInformationUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: WorkerInformation) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/worker-information`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      },
    }
  );
};

type ChangeInvoiceStatusRequestBody =
  | {
      changeInvoiceUploadedByClient: true;
    }
  | {
      changeInvoicePaidByClient: true;
    }
  | {
      changeTaxBooking: true;
    };

export const useInstallationInvoiceStatusUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: ChangeInvoiceStatusRequestBody) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/change-invoice-status`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
        queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
      },
    }
  );
};

type AssignInstallationRequestBody =
  | {
      setupAssignedTo: string | null;
    }
  | {
      pickupAssignedTo: string | null;
    };

export const useInstallationAssign = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: AssignInstallationRequestBody) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/assign-installation`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      },
    }
  );
};

type WorkerOrderRequestBody =
  | {
      setupOrderNumber: number | null;
    }
  | {
      pickupOrderNumber: number | null;
    };

export const useInstallationWorkerOrderNumberUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: WorkerOrderRequestBody) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/worker-order-number`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
      },
    }
  );
};

type InvoicePayRequestBody = {
  invoicePaid: boolean;
  invoicePaidDateTime?: string;
};

export const useInstallationInvoicePay = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: InvoicePayRequestBody) =>
      new AuthorizedService()
        .put(`${endPoint}/${id}/invoice-pay`, data)
        .then((res: any) => res),
    {
      onSuccess: () => {
        message.success(t("general:updatedSuccess"));
      },
      onError: () => {
        message.error(t("general:updateFail"));
      },
      onSettled: () => {
        queryClient.invalidateQueries(INSTALLATION_LIST_QUERY_KEY);
        queryClient.invalidateQueries(GET_STATUS_WISE_COUNT_QUERY_KEY);
      },
    }
  );
};
