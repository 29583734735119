import { Modal, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Spinner = ({ tip = "Loading...", ...rest }) => (
  <Spin tip={tip} {...rest} />
);

export const Loader = () => (
  <Modal width={100} visible={true} footer={false} closable={false}>
    <Spin tip="Submitting" />
  </Modal>
);

export const LoadingSpinner = () => <LoadingOutlined spin />;

export const LoadingDiv = () => (
  <div className="flex justify-center">
    <LoadingSpinner />
  </div>
);
