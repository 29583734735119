import { useQuery } from "react-query";
import { AuthorizedService } from "shared/axios";
import { Instruction } from "types/instruction";
import { instructionKeys } from "utils/constant/queryKey";

const END_POINT = "/instruction";

export const useInstructionList = () => {
  return useQuery<Instruction[]>({
    queryKey: instructionKeys.list(),
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT);
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};
