import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Image, Modal, Popconfirm } from "antd";
import { LabelValue } from "pages/orders/orderModal/TogglingInput";
import { useGetParkedCarList } from "queryHook/parkedCar";
import {
  WorkerInstallationQueryParams,
  usePostWorkerInstallationDone,
} from "queryHook/workerOrder";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { EventType, InstallationSchema } from "types/installation";
import { formattedDateTime } from "utils/global";
import {
  getBarrierDetailsCity,
  getBarrierDetailsCustomerAddress,
  getBarrierDetailsCustomerAddressWithTwoSideBlocking,
  getUpdatedIsTwoSiteBlocking,
  getUpdatedWithOutsideLift,
} from "utils/global/installationHelper";
import WorkerParkedCar from "./WorkerParkedCar";
import WorkerParkedCarList from "./WorkerParkedCarList";
import WorkerUpload from "./WorkerUpload";

interface WorkerModalProps {
  visible: boolean;
  onHide: MouseEventHandler<HTMLElement>;
  modalText: string;
  installation: InstallationSchema;
  event: EventType;
  params: WorkerInstallationQueryParams;
}

const WorkerModal = ({
  visible,
  onHide,
  modalText,
  installation,
  event,
  params,
}: WorkerModalProps) => {
  const { t } = useTranslation();

  const { data: parkedCars } = useGetParkedCarList(installation.id);

  const { mutate, isLoading } = usePostWorkerInstallationDone(
    installation.id,
    params
  );

  const onDone = () => {
    mutate({ event });
  };

  const isSetup = event === "setup";

  const isSetupDone = !!installation.setupDoneDateTime;
  const isPickupDone = !!installation.pickupDoneDateTime;
  const isDone = isSetup ? isSetupDone : isPickupDone;

  const { withOutsideLift, additionalInformation, isTwoSiteBlock } =
    installation;

  const updatedWithOutsideLift = getUpdatedWithOutsideLift(installation);

  const updatedIsTwoSiteBlock = getUpdatedIsTwoSiteBlocking(installation);

  const customerAddress = getBarrierDetailsCustomerAddress(installation);

  const formattedCustomerAddress =
    getBarrierDetailsCustomerAddressWithTwoSideBlocking(installation);

  const barrierDetailsCity = getBarrierDetailsCity(installation);

  return (
    <Modal
      visible={visible}
      title={
        <div className="installation-modal-header">
          <div>
            <div>{modalText}</div>
            <DoneButton
              onDone={onDone}
              isDisabled={isDone}
              isLoading={isLoading}
            />
          </div>
        </div>
      }
      onCancel={onHide}
      width={600}
      footer={[
        <Button key="back" onClick={onHide}>
          {t("main:close")}
        </Button>,
      ]}
    >
      <div className="flex flex-col gap-4">
        <div>
          <LabelValue
            label={t("orders:withOutsideLift")}
            originalValue={withOutsideLift ? t("orders:yes") : t("orders:no")}
            updatedValue={additionalInformation?.additionalWithOutsideLift}
            className={updatedWithOutsideLift ? "text-red font-700" : undefined}
          />

          <LabelValue
            label={t("orders:isTwoSiteBlock")}
            originalValue={isTwoSiteBlock ? t("orders:yes") : t("orders:no")}
            updatedValue={additionalInformation?.additionalIsTwoSiteBlock}
            className={updatedIsTwoSiteBlock ? "text-red font-700" : undefined}
          />

          <LabelValue
            originalValue={formattedCustomerAddress}
            label={t("orders:barrierDetails.customer")}
            className="text-green"
          />

          <LabelValue
            originalValue={barrierDetailsCity}
            label={t("orders:barrierDetails.city")}
            className={
              customerAddress === barrierDetailsCity ? "text-green" : "text-red"
            }
          />
          <LabelValue
            originalValue={installation.setupDate}
            label={t("orders:barrierDetails.setupDate")}
          />

          <LabelValue
            originalValue={
              isSetup
                ? formattedDateTime(installation.setupDoneDateTime) ?? "-"
                : formattedDateTime(installation.pickupDoneDateTime) ?? "-"
            }
            label={`${t("general:done")}`}
          />
        </div>

        <div>
          <h5 className="underline">{t("general:instructions")}:</h5>
          {installation.workerInstructionNumber ? (
            <Image
              src={`/img/instruction/${installation.workerInstructionNumber}.jpg`}
              alt={`${t("general:instructions")} ${
                installation.workerInstructionNumber
              }`}
              height={200}
              style={{
                objectFit: "contain",
              }}
            />
          ) : (
            <div>-</div>
          )}
        </div>
        <WorkerParkedCar installationId={installation.id} />
        <WorkerParkedCarList
          installationId={installation.id}
          parkedCars={parkedCars}
        />
        <div>
          <h5 className="underline">{t("orders:notice.notice")}:</h5>
          <p className="m-0">{installation.workerNotice || "-"}</p>
        </div>
        <WorkerUpload installationId={installation.id} />
      </div>
    </Modal>
  );
};

export default WorkerModal;

function DoneButton({
  onDone,
  isDisabled,
  isLoading,
}: {
  isLoading: boolean;
  isDisabled: boolean;
  onDone: () => void;
}) {
  const { t } = useTranslation();

  return (
    <Popconfirm
      placement="bottom"
      icon={<QuestionCircleOutlined style={{ color: "red" }} />}
      title={t("general:confirmMessage")}
      onConfirm={onDone}
      okText={t("general:yes")}
      cancelText={t("general:no")}
      disabled={isDisabled}
    >
      <Button
        type="primary"
        className="modal-button"
        disabled={isDisabled || isLoading}
        style={{ opacity: isDisabled ? 0.5 : 1 }}
      >
        {t("general:done")}
      </Button>
    </Popconfirm>
  );
}
