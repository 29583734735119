import Done from "pages/orders/Done";
import { useTranslation } from "react-i18next";
import { InstallationSchema } from "types/installation";
import Instruction from "./Instruction";
import WorkerNotice from "./WorkerNotice";

export default function WorkerTab({
  installation,
}: {
  installation: InstallationSchema;
}) {
  const { t } = useTranslation();

  return (
    <div>
      <h5 className="underline">{t("orders:modalTabs:worker")}</h5>
      <div className="flex flex-col gap-4">
        <Done installation={installation} />
        <Instruction installation={installation} />
        <WorkerNotice installation={installation} />
      </div>
    </div>
  );
}
