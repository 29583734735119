import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import {
  City,
  CreateCityRequestBodyDTO,
  UpdateCityRequestBodyDTO,
} from "types/city";
import { cityKeys } from "utils/constant/queryKey";

const END_POINT = "/city";

export const useCityList = () => {
  return useQuery<City[]>({
    queryKey: cityKeys.list(),
    queryFn: async () => {
      const res = await new AuthorizedService().get(`${END_POINT}/all`);
      return res.data;
    },
    staleTime: Infinity,
    cacheTime: Infinity,
  });
};

export const useCityListPaginated = (params) => {
  return useQuery<PaginatedResponse<City>>({
    queryKey: cityKeys.listPaginated(params),
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
  });
};

export const useCityFindOne = (cityId: string) => {
  return useQuery<City>({
    queryKey: cityKeys.find(cityId),
    queryFn: async () => {
      const res = await new AuthorizedService().get(`${END_POINT}/${cityId}`);
      return res.data;
    },
  });
};

export const useCityCreate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: CreateCityRequestBodyDTO) =>
      new AuthorizedService().post(END_POINT, data).then((res) => res),
    onSuccess: () => {
      queryClient.invalidateQueries(cityKeys.all);
    },
  });
};

export const useCityUpdate = (id: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: UpdateCityRequestBodyDTO) =>
      new AuthorizedService()
        .put(`${END_POINT}/${id}`, data)
        .then((res) => res),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(cityKeys.all);
      },
    }
  );
};
