import { Divider, Spin } from "antd";
import { useCustomizableList } from "queryHook/customizable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Customizable, CustomizableKey } from "types/customizable";
import CustomerMailBodyForm from "./CustomerMailBodyForm";
import ReplaceableRuleForm from "./ReplaceableRuleForm";

const CustomizablePage = () => {
  const { t } = useTranslation();

  const { data, isLoading } = useCustomizableList();

  if (isLoading) {
    return <Spin />;
  }

  if (!data) {
    return <div>{t("warnings:error")}</div>;
  }

  return (
    <div className="flex flex-col gap-4">
      <div style={{ padding: "1rem", background: "white" }}>
        <h5>{t("menu:Customizables")}</h5>
        <Divider orientation="left" style={{ margin: 0 }} />
      </div>

      <CustomizableForm data={data} />
    </div>
  );
};
export default CustomizablePage;

function CustomizableForm({ data }: { data: Customizable[] }) {
  const customizables = useMemo(() => {
    const c = new Map<CustomizableKey, Customizable>();
    data.forEach((cus) => {
      c.set(cus.key, cus);
    });
    return c;
  }, [data]);

  const customerMailSubject = customizables.get("customerMailSubject");
  const customerMailBody = customizables.get("customerMailBody");
  const paymentId = customizables.get("paymentId");
  const firstName = customizables.get("firstName");
  const lastName = customizables.get("lastName");
  const gender = customizables.get("gender");

  return (
    <div className="flex flex-col gap-4">
      {customerMailSubject ? (
        <ReplaceableRuleForm
          customizableKey="customerMailSubject"
          customizable={customerMailSubject}
        />
      ) : null}

      {customerMailBody ? (
        <CustomerMailBodyForm customizable={customerMailBody} />
      ) : null}

      {paymentId ? (
        <ReplaceableRuleForm
          customizableKey="paymentId"
          customizable={paymentId}
        />
      ) : null}

      {firstName ? (
        <ReplaceableRuleForm
          customizableKey="firstName"
          customizable={firstName}
        />
      ) : null}

      {lastName ? (
        <ReplaceableRuleForm
          customizableKey="lastName"
          customizable={lastName}
        />
      ) : null}

      {gender ? (
        <ReplaceableRuleForm customizableKey="gender" customizable={gender} />
      ) : null}
    </div>
  );
}
