export enum CustomizableType {
  FIELD = "field",
  RULE = "rule",
}

export type CustomizableKey =
  | "customerMailSubject"
  | "customerMailBody"
  | "paymentId"
  | "firstName"
  | "lastName"
  | "gender";

export type Customizable = {
  id: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  key: CustomizableKey;
  value: string;
  type: CustomizableType;
};
