// Pattern adopted from the following blog post:
// https://tkdodo.eu/blog/effective-react-query-keys

export const cityKeys = {
  all: ["city"] as const,
  list: () => [...cityKeys.all, "list"] as const,
  listPaginated: (params: unknown) =>
    [...cityKeys.all, "listPaginated", params] as const,
  find: (id: string) => [...cityKeys.all, "find", id] as const,
};

export const instructionKeys = {
  all: ["instruction"] as const,
  list: () => [...instructionKeys.all, "list"] as const,
};

export const workerKeys = {
  all: ["worker"] as const,
  list: () => [...workerKeys.all, "list"] as const,
  listPaginated: (params: unknown) =>
    [...workerKeys.all, "listPaginated", params] as const,
  find: (id: string) => [...workerKeys.all, "find", id] as const,
};
