import { FormInstance, message } from "antd";
import { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AuthorizedService } from "shared/axios";
import { PaginatedResponse } from "types";
import { WorkerSchema } from "types/worker";
import { workerKeys } from "utils/constant/queryKey";
import { isAdmin } from "utils/global";

const END_POINT = "/worker";

export const useWorkerList = () => {
  return useQuery<WorkerSchema[]>({
    queryKey: workerKeys.list(),

    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, {
        size: "all",
      });
      return res.data;
    },
    enabled: isAdmin(),
  });
};

export const useWorkerListPaginated = (params) => {
  return useQuery<PaginatedResponse<WorkerSchema>>({
    queryKey: workerKeys.listPaginated(params),
    queryFn: async () => {
      const res = await new AuthorizedService().get(END_POINT, params);
      return res.data;
    },
    enabled: isAdmin(),
  });
};

export const useWorkerFindOne = (workerId: string) => {
  return useQuery<WorkerSchema>({
    queryKey: workerKeys.find(workerId),
    queryFn: async () => {
      const res = await new AuthorizedService().get(`${END_POINT}/${workerId}`);
      return res.data;
    },
    enabled: isAdmin(),
  });
};

export const useWorkerCreate = (formRef: RefObject<FormInstance>) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: any) => {
      await new AuthorizedService().post(END_POINT, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workerKeys.all);
      message.success(t("general:createdSuccess"));
    },
    onError: (e: any) => {
      if (e?.status !== 400) {
        message.error(t("general:createFail"));
        return;
      }

      if (e?.data?.message?.email === "Email is taken!") {
        formRef.current?.setFields([
          {
            name: "email",
            errors: [t("warnings:emailTaken")],
          },
        ]);
      }

      if (e?.data?.message?.userName === "Username is taken!") {
        formRef.current?.setFields([
          {
            name: "userName",
            errors: [t("warnings:userNameTaken")],
          },
        ]);
      }
    },
  });
};

export const useWorkerUpdate = (workerId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: FormData) => {
      await new AuthorizedService().put(`${END_POINT}/${workerId}`, data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workerKeys.all);
      message.success(t("general:updatedSuccess"));
    },
    onError: () => {
      message.error(t("general:updateFail"));
    },
  });
};

export const useWorkerDelete = (workerId: string) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await new AuthorizedService().delete(`${END_POINT}/${workerId}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(workerKeys.all);
      message.success(t("general:deleteSuccess"));
    },
    onError: () => {
      message.error(t("general:deleteFail"));
    },
  });
};
