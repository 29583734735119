import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Popconfirm, Select } from "antd";
import { useInstallationAssign } from "queryHook/installation";
import { useWorkerList } from "queryHook/worker";
import { useTranslation } from "react-i18next";
import { BiX } from "react-icons/bi";
import { InstallationSchema } from "types/installation";

export default function AssignWorkerSelect({
  row,
}: {
  row: InstallationSchema;
}) {
  const { t } = useTranslation();

  const { mutate } = useInstallationAssign(row.id);

  const onSetupAssigneeChange = (value: string | undefined) => {
    mutate({
      setupAssignedTo: value ?? null,
    });
  };

  const onPickupAssigneeChange = (value: string | undefined) => {
    mutate({
      pickupAssignedTo: value ?? null,
    });
  };

  return (
    <Form className="small-label-form">
      <WorkerOrderSelect
        value={row.assignedTo?.userName}
        placeholder={t("orders:setup")}
        onChange={onSetupAssigneeChange}
      />
      <WorkerOrderSelect
        value={row.pickupAssignedTo?.userName}
        placeholder={t("orders:pickup")}
        onChange={onPickupAssigneeChange}
      />
    </Form>
  );
}

function WorkerOrderSelect({
  placeholder,
  onChange,
  value,
}: {
  placeholder: string;
  onChange: (value: string | undefined) => void;
  value: string | undefined;
}) {
  const { t } = useTranslation();

  const { data: workerList } = useWorkerList();

  return (
    <Form.Item labelAlign="left" className="worker-order-select m-0">
      <Select
        style={{ width: 200 }}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
      >
        {workerList?.map((worker) => {
          return (
            <Select.Option key={worker.userName} value={worker.userName}>
              {worker.firstName} {worker.lastName}
            </Select.Option>
          );
        })}
      </Select>

      <Popconfirm
        placement="bottom"
        icon={<QuestionCircleOutlined style={{ color: "red" }} />}
        title={t("general:confirmMessage")}
        onConfirm={() => onChange(undefined)}
        okText={t("orders:yes")}
        cancelText={t("orders:no")}
        disabled={!value}
      >
        <Button
          type="dashed"
          className="modal-button"
          disabled={!value}
          style={{
            opacity: value ? 1 : 0.5,
            paddingInline: "12px",
            minHeight: "30px",
          }}
        >
          <BiX />
        </Button>
      </Popconfirm>
    </Form.Item>
  );
}
