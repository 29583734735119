import { Button, Col, ColProps, Form, Image, Input, Row, RowProps } from "antd";
import FileSelector from "components/reusable/FileSelector";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { WorkerSchema } from "types/worker";
import { IMAGE_FALLBACK } from "utils/global/constants";

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

const rowStyles: RowProps = {
  gutter: 16,
};

type WorkerFormProps = {
  worker?: WorkerSchema;
  isEdit?: boolean;
  isLoading: boolean;
  file: File | null;
  setFile: Dispatch<SetStateAction<File | null>>;
};

const ACCEPTED_FILE_TYPES = {
  "image/*": [".png", ".jpeg"],
};

export default function WorkerForm({
  worker,
  isEdit = false,
  isLoading,
  file,
  setFile,
}: WorkerFormProps) {
  const { t } = useTranslation();

  // This is a hack to disable autofill like userName, email and password done by browser automatically
  const handleFocus = (event) => {
    event.target.removeAttribute("readonly");
  };

  return (
    <>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("general:firstName")}
            name="firstName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={worker?.firstName}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("general:lastName")}
            name="lastName"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={worker?.lastName}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      {!isEdit && (
        <>
          <Row {...rowStyles}>
            <Col {...colStyles}>
              <Form.Item
                label={t("general:userName")}
                name="userName"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                ]}
              >
                <Input readOnly onFocus={handleFocus} />
              </Form.Item>
            </Col>
            <Col {...colStyles}>
              <Form.Item
                label={t("login:email")}
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("warnings:invalidValue"),
                  },
                ]}
              >
                <Input readOnly onFocus={handleFocus} />
              </Form.Item>
            </Col>
          </Row>
          <Row {...rowStyles}>
            <Col {...colStyles}>
              <Form.Item
                label={t("login:password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("warnings:required"),
                  },
                ]}
              >
                <Input.Password readOnly onFocus={handleFocus} />
              </Form.Item>
            </Col>
          </Row>
        </>
      )}
      <Row {...rowStyles}>
        <Col span={24}>
          <Form.Item label={t("worker:signature")}>
            <FileSelector
              acceptedFileType={ACCEPTED_FILE_TYPES}
              previewColumn={<FilePreview file={file} worker={worker} />}
              setValue={setFile}
              multiple={false}
            />
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t("general:submit")}
      </Button>
    </>
  );
}

function FilePreview({
  file,
  worker,
}: {
  file: File | null;
  worker: WorkerSchema | undefined;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex gap-2" style={{ height: "180px" }}>
      <LocalFilePreview file={file} />
      {worker?.signatureFileUrl ? (
        <div style={{ position: "relative" }}>
          <Image
            src={worker.signatureFileUrl}
            width="auto"
            height="100%"
            style={{ objectFit: "contain" }}
            fallback={IMAGE_FALLBACK}
          />
          {file && (
            <div className="file-preview-overlay">
              {t("worker:deleteSignatureWarning")}
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}

function LocalFilePreview({ file }: { file: File | null }) {
  if (!file) {
    return null;
  }

  const src = window.URL.createObjectURL(file);

  return (
    <Image
      src={src}
      width="auto"
      height="100%"
      style={{ objectFit: "contain" }}
      fallback={IMAGE_FALLBACK}
    />
  );
}
