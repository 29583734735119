import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";
import { useShowInputContext } from "context/ShowInputContext";
import { AdditionalInstallationInformation } from "types/installation";

export default function TogglingInput({
  name,
  label,
  placeholder,
  originalValue,
  updatedValue,
  rules,
  className,
}: {
  name:
    | keyof AdditionalInstallationInformation
    | "updatedDateRangeFrom"
    | "updatedDateRangeTo";
  label: string;
  placeholder?: string;
  originalValue: string | null;
  updatedValue: string | undefined;
  rules?: Rule[];
  className?: string;
}) {
  const { showInput, isLoading } = useShowInputContext();

  return (
    <div>
      <LabelValue
        label={label}
        originalValue={originalValue}
        updatedValue={updatedValue}
        className={className}
      />
      {showInput && (
        <Form.Item name={name} initialValue={updatedValue} rules={rules}>
          <Input disabled={isLoading} placeholder={placeholder} />
        </Form.Item>
      )}
    </div>
  );
}

export function LabelValue({
  label,
  originalValue,
  updatedValue,
  className,
}: {
  label: string;
  originalValue: string | null;
  updatedValue?: string | undefined;
  className?: string;
}) {
  return (
    <div>
      <b>{label}: </b>
      <p className={className}>
        {originalValue || "-"}&nbsp;
        {updatedValue && originalValue !== updatedValue && (
          <span className="italic text-red">({updatedValue})</span>
        )}
      </p>
    </div>
  );
}
