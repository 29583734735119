import { Button, Col, ColProps, Form, Input, Row, RowProps } from "antd";
import { useTranslation } from "react-i18next";
import { City } from "types/city";

const colStyles: ColProps = {
  xs: 24,
  md: 12,
};

const rowStyles: RowProps = {
  gutter: 16,
};

export default function CityForm({
  isLoading,
  city: city,
}: {
  isLoading: boolean;
  city?: City;
}) {
  const { t } = useTranslation();

  const positiveNumberValidator = (_rule, value) => {
    if (!value) return Promise.reject(t("warnings:required"));

    const num = Number(value);

    if (Number.isInteger(num) && num > 0) return Promise.resolve();
    return Promise.reject(t("warnings:invalidValue"));
  };

  return (
    <>
      <Row {...rowStyles}>
        <Col {...colStyles} md={24}>
          <Form.Item
            label={t("cities:name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("warnings:required"),
              },
            ]}
            initialValue={city?.name}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("cities:basePrice")}
            name="basePrice"
            required
            rules={[
              {
                validator: positiveNumberValidator,
              },
            ]}
            initialValue={city?.basePrice}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col {...colStyles}>
          <Form.Item
            label={t("cities:permissionPrice")}
            name="permissionPrice"
            required
            rules={[
              {
                validator: positiveNumberValidator,
              },
            ]}
            initialValue={city?.permissionPrice}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row {...rowStyles}>
        <Col {...colStyles}>
          <Form.Item
            label={t("cities:withOutsideLiftPrice")}
            name="withOutsideLiftPrice"
            required
            rules={[
              {
                validator: positiveNumberValidator,
              },
            ]}
            initialValue={city?.withOutsideLiftPrice}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Button type="primary" htmlType="submit" loading={isLoading}>
        {t("general:submit")}
      </Button>
    </>
  );
}
