import { BiBuilding, BiCustomize, BiTask, BiUser } from "react-icons/bi";
import { BsBank } from "react-icons/bs";
import { GrDashboard, GrOrderedList } from "react-icons/gr";

export const menuData = {
  items: [
    {
      id: 1,
      title: "Dashboard",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrDashboard />,
      url: "/dashboard",
      orderNumber: 1,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin", "worker"],
      sidebarCountData: "",
    },
    {
      id: 2,
      title: "Order",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <GrOrderedList />,
      url: "/order/all",
      orderNumber: 12,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "allBooking",
    },
    {
      id: 3,
      title: "Order",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <GrOrderedList />,
      url: "/worker-order/all",
      orderNumber: 2,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["worker"],
      sidebarCountData: "",
    },
    {
      id: 4,
      title: "Task",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <BiTask />,
      url: "/task/all",
      orderNumber: 2,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 6,
      title: "Authority",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <BsBank />,
      url: "/authority/all",
      orderNumber: 3,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 7,
      title: "AddAuthority",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/authority/add",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 8,
      title: "EditAuthority",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/authority/edit/:id",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 9,
      title: "Worker",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <BiUser />,
      url: "/worker/all",
      orderNumber: 5,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 10,
      title: "AddWorker",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/worker/add",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 11,
      title: "EditWorker",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/worker/edit/:id",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 12,
      title: "Cities",
      parentId: null,
      slug: "admin-ycicbn",
      icon: <BiBuilding />,
      url: "/city/all",
      orderNumber: 4,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 13,
      title: "AddCity",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/city/add",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 14,
      title: "EditCity",
      parentId: null,
      slug: "admin-ycicbn",
      icon: null,
      url: "/city/edit/:id",
      orderNumber: 0,
      group: "",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
    {
      id: 15,
      title: "NewOrderH",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrOrderedList />,
      url: "/new-order",
      orderNumber: 6,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "newOrder",
    },
    {
      id: 16,
      title: "NewOrderE",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrOrderedList />,
      url: "/new-order-e",
      orderNumber: 7,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "newOrderWithEmail",
    },
    {
      id: 17,
      title: "OpenOrder",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrOrderedList />,
      url: "/open-order",
      orderNumber: 8,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "openOrder",
    },
    {
      id: 18,
      title: "AccountingOrder",
      parentId: null,
      slug: "admin-ycicd",
      icon: <GrOrderedList />,
      url: "/accounting-order",
      orderNumber: 9,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "accountingOrder",
    },
    {
      id: 19,
      title: "CompletedOrder",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrOrderedList />,
      url: "/complete-order",
      orderNumber: 11,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "completeOrder",
    },
    {
      id: 20,
      title: "RejectCancelOrder",
      parentId: null,
      slug: "admin-ycicb",
      icon: <GrOrderedList />,
      url: "/reject-cancel-order",
      orderNumber: 10,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "rejectCancelOrder",
    },
    {
      id: 21,
      title: "Customizables",
      parentId: null,
      slug: "admin-ycicb",
      icon: <BiCustomize />,
      url: "/customizables",
      orderNumber: 13,
      group: "sidebar",
      attributes: {
        isDisabled: false,
      },
      visibleFor: ["admin"],
      sidebarCountData: "",
    },
  ],
};
