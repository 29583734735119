import { Form, Modal, Tabs } from "antd";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Customizable } from "types/customizable";
import { InstallationSchema } from "types/installation";
import { stringTemplatingReplaceAll } from "utils/global";
import { getSalutationFromGender } from "utils/global/installationHelper";
import EmailForm from "./EmailForm";
import EmailPreview from "./EmailPreview";

interface EmailModalProps {
  visible: boolean;
  handleCancel: () => void;
  installation: InstallationSchema;
  customizables: Customizable[];
}

export interface EmailData {
  subject: string;
  emailBodyText: string;
}

const EmailModal = ({
  visible,
  handleCancel,
  installation,
  customizables,
}: EmailModalProps) => {
  const { t } = useTranslation();

  const customizableMap = useMemo(() => {
    const c = new Map<string, Customizable>();
    customizables.forEach((cus) => {
      c.set(cus.key, cus);
    });
    return c;
  }, [customizables]);

  const customerMailSubject = customizableMap.get("customerMailSubject")?.value;
  const customerMailBody = customizableMap.get("customerMailBody")?.value;
  const paymentId = customizableMap.get("paymentId")?.value;
  const firstName = customizableMap.get("firstName")?.value;
  const lastName = customizableMap.get("lastName")?.value;
  const gender = customizableMap.get("gender")?.value;

  const replacingValues = [
    {
      pattern: paymentId,
      value: installation.payment.paymentId,
    },
    {
      pattern: firstName,
      value: installation.customer.firstName,
    },
    {
      pattern: lastName,
      value: installation.customer.lastName,
    },
    {
      pattern: gender,
      value: installation.customer.gender
        ? getSalutationFromGender(installation.customer.gender)
        : "",
    },
  ];

  const initialEmailSubject = stringTemplatingReplaceAll(
    customerMailSubject || "",
    replacingValues
  );
  const initialEmailBody = stringTemplatingReplaceAll(
    customerMailBody || "",
    replacingValues
  );

  const [form] = Form.useForm<EmailData>();

  // Manually added state because there is no useWatch hook in this version of antd
  // If we upgrade it, there are breaking changes
  const [subject, setSubject] = useState(initialEmailSubject);
  const [emailBody, setEmailBody] = useState(initialEmailBody);

  return (
    <Modal
      visible={visible}
      title={t("email:newMessage")}
      onCancel={handleCancel}
      style={{ maxWidth: "900px" }}
      bodyStyle={{
        height: 1000,
        overflow: "hidden",
        padding: "8px",
      }}
      footer={null}
    >
      <Tabs
        defaultActiveKey="1"
        style={{ maxHeight: "100%", paddingLeft: "8px" }}
        className="order-modal-tab"
      >
        <Tabs.TabPane tab={t("email:email")} key="overview">
          <EmailForm
            installation={installation}
            handleCancel={handleCancel}
            emailBody={emailBody}
            setEmailBody={setEmailBody}
            subject={subject}
            setSubject={setSubject}
            paymentId={paymentId}
            firstName={firstName}
            lastName={lastName}
            gender={gender}
            form={form}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t("email:preview")} key="preview">
          <EmailPreview
            to={installation.customer.email}
            subject={subject}
            emailBody={emailBody}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default EmailModal;
