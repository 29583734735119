import { Form, FormInstance, Input } from "antd";
import { Rule } from "antd/lib/form";
import { useShowInputContext } from "context/ShowInputContext";
import { ChangeEvent, ChangeEventHandler, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { InstallationSchema, OrderModelForm } from "types/installation";
import { validateDate } from "utils/global";
import {
  getBarrierDetailsCity,
  getBarrierDetailsCustomerAddress,
  getBarrierDetailsCustomerAddressWithTwoSideBlocking,
  getBarrierDetailsHalteverbot24,
} from "utils/global/installationHelper";

export default function BarrierDetails({
  installation,
  form,
}: {
  installation: InstallationSchema;
  form?: FormInstance<OrderModelForm>;
}) {
  const { t } = useTranslation();

  const { setupDate, pickupDate } = installation;

  const customerAddress = getBarrierDetailsCustomerAddress(installation);

  const formattedCustomerAddress =
    getBarrierDetailsCustomerAddressWithTwoSideBlocking(installation);

  const barrierDetailsHalteverbot24 =
    getBarrierDetailsHalteverbot24(installation);

  const barrierDetailsCity = getBarrierDetailsCity(installation);

  const onHalteverbot24Change = (e: ChangeEvent<HTMLInputElement>) => {
    form?.setFieldsValue({
      barrierDetailsCity: e.target.value,
    });
  };

  useEffect(() => {
    form?.setFieldsValue({
      barrierDetailsSetupDate: installation.setupDate,
    });
  }, [installation.setupDate]);

  useEffect(() => {
    form?.setFieldsValue({
      barrierDetailsPickupDate: installation.pickupDate,
    });
  }, [installation.pickupDate]);

  return (
    <div>
      <h5 className="underline">
        {t("orders:barrierDetails.barrierDetails")}:
      </h5>
      <div className="flex gap-4">
        <div className="flex-1">
          <BarrierDetailsTogglingInput
            name="barrierDetailsCustomer"
            value={formattedCustomerAddress}
            label={`${t("orders:barrierDetails.customer")}:`}
            disabled={true}
            className="text-green"
          />
          <BarrierDetailsTogglingInput
            name="barrierDetailsHalteverbot24"
            value={barrierDetailsHalteverbot24}
            label={`${t("orders:barrierDetails.appliedForWebsite")}:`}
            onChange={onHalteverbot24Change}
          />
          <BarrierDetailsTogglingInput
            name="barrierDetailsCity"
            value={barrierDetailsCity}
            label={`${t("orders:barrierDetails.city")}:`}
            key={barrierDetailsCity}
            className={
              customerAddress === barrierDetailsCity ? "text-green" : "text-red"
            }
          />
        </div>
        <div className="flex-1">
          <BarrierDetailsTogglingInput
            name="barrierDetailsSetupDate"
            value={setupDate}
            label={`${t("orders:barrierDetails.setupDate")}:`}
            placeholder="YYYY-MM-DD"
            rules={[
              {
                validator: validateDate,
              },
            ]}
          />
          <BarrierDetailsTogglingInput
            name="barrierDetailsPickupDate"
            value={pickupDate}
            label={`${t("orders:barrierDetails.pickupDate")}:`}
            placeholder="YYYY-MM-DD"
            rules={[
              {
                validator: validateDate,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

function BarrierDetailsTogglingInput({
  value,
  label,
  name,
  placeholder,
  disabled,
  onChange = undefined,
  rules,
  className,
}: {
  value: string;
  label: string;
  name: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  rules?: Rule[];
  className?: string;
}) {
  const { showInput, isLoading } = useShowInputContext();

  return (
    <div>
      <div>
        <b>{label}</b>
      </div>
      {showInput ? (
        <Form.Item name={name} initialValue={value} rules={rules}>
          <Input
            disabled={disabled || isLoading}
            onChange={onChange}
            placeholder={placeholder}
          />
        </Form.Item>
      ) : (
        <p className={className}>{value}</p>
      )}
    </div>
  );
}
