export default function CollapsePanelHeader({
  textKey,
  totalNumber,
}: {
  textKey: string;
  totalNumber?: string;
}) {
  return (
    <>
      <span className="underline mt-5">{textKey}</span>

      {totalNumber !== undefined && (
        <span style={{ marginLeft: "8px", color: "red" }}>({totalNumber})</span>
      )}
    </>
  );
}
