import { Button, Image, Modal } from "antd";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import { WorkerSchema } from "types/worker";
import {
  IMAGE_FALLBACK,
  IMAGE_PREVIEW_HEIGHT,
  IMAGE_PREVIEW_WIDTH,
} from "utils/global/constants";

type WorkerModalProps = {
  visible: boolean;
  onHide: MouseEventHandler<HTMLElement>;
  modalText: string;
  defaultValue: WorkerSchema;
};

export default function WorkerModal({
  visible,
  onHide,
  modalText,
  defaultValue,
}: WorkerModalProps) {
  const { t } = useTranslation();

  const { firstName, lastName, userName, email, signatureFileUrl } =
    defaultValue;

  return (
    <Modal
      visible={visible}
      title={<div>{modalText}</div>}
      onCancel={onHide}
      width={600}
      footer={
        <div>
          <Button key="back" onClick={onHide}>
            {t("main:close")}
          </Button>
        </div>
      }
    >
      <div>
        <div className="grid grid-cols-2">
          <div>
            <b>{t("general:firstName")}: </b>
            <p>{firstName}</p>
          </div>
          <div>
            <b>{t("general:lastName")}: </b>
            <p>{lastName}</p>
          </div>
          <div>
            <b>{t("general:userName")}: </b>
            <p>{userName}</p>
          </div>
          <div>
            <b>{t("login:email")}: </b>
            <p>{email ?? "-"}</p>
          </div>
        </div>
        <div>
          <b>{t("worker:signature")}: </b>
          <div className="grid grid-cols-2 gap-2">
            {signatureFileUrl ? (
              <Image
                src={signatureFileUrl}
                width={IMAGE_PREVIEW_WIDTH}
                height={IMAGE_PREVIEW_HEIGHT}
                style={{ objectFit: "contain" }}
                fallback={IMAGE_FALLBACK}
              />
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}
